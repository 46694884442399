<template>
    <div class="box main-navbar">
        <el-menu class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :collapse="isCollapse"
            :unique-opened="true" background-color="#1D78FE" text-color="#C2D3FF" active-text-color="#fff"
            :collapse-transition="false" :default-active="currentMenu">
            <div class="introduce">
                <span>内容</span>
            </div>
            <div>
                <fragment v-for="(item, index) in list" :key="index">
                    <el-submenu v-if="item.page && item.has_child == 1" :index="item.route">
                        <template slot="title">
                            <local-svg :iconClass="item.icon" class="icon" :class="{ 'big-icon': isCollapse }"
                                :style="{ padding: !!item.spacing ? item.spacing + 'px' : '' }"></local-svg>
                            <span v-if="!isCollapse">{{ item.name }}</span>
                        </template>
                        <el-menu-item-group v-for="(tab, sonIndex) in item.page" :key="sonIndex">
                            <el-menu-item :index="tab.route" @click="changPage(tab)" class="child-nav"
                                :class="{ 'is-active': currentMenu === tab.route && currentMenu !== '' }">{{
                                    tab.name
                                }}</el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>
                    <el-menu-item class="title-first" v-else
                        :class="{ 'is-active': currentMenu === item.route && currentMenu !== '' }" :index="item.route"
                        @click="changPage(item)">
                        <local-svg :iconClass="item.icon + ''" class="icon" :class="{ 'big-icon': isCollapse }"
                            :style="{ padding: !!item.spacing ? item.spacing + 'px' : '' }"></local-svg>
                        <span slot="title">{{ item.name }}</span>
                    </el-menu-item>
                </fragment>
            </div>
        </el-menu>
    </div>
</template>

<script>

import vm from '@/assets/js/closeNav'
export default {
    name: "NavBarLeft",
    props: {
        list: {
            type: Array,
            default: () => {
                return []
            }
        },
        isCollapse: Boolean,
    },
    data() {
        return {
            currentMenu: '',
            version: '',
            change_date: '',
            isOpen: false
        };
    },
    mounted() {
        this.currentMenu = this.$route.path
    },
    watch: {
        // $route(e) {
        //     this.currentMenu = e.path;
        //     this.$store.commit('setRoute', e.path)
        // },
        $route(to, from) {

            this.currentMenu = to.path;
            this.$store.commit('setRoute', to.path);

            console.log(this.currentMenu, 'currentMenu111')
        },
    },
    methods: {
        handleOpen() {
            this.isOpen = true
        },
        handleClose() {
            this.isOpen = false
        },
        changPage(data) {
            console.log(data.route, 99999999)
            this.$router.push(data.route);
            this.$nextTick(() => {
                this.currentMenu = data.route;
                let obj = data;
                vm.$emit("closeobj", obj);
            });

            console.log(this.currentMenu, 'currentMenu222')
        },
    }
}
</script>

<style lang="scss">
.box {
    height: 100%;
    min-height: 100%;
    position: relative;

    .el-menu-vertical-demo {
        box-sizing: border-box;
        height: auto;
        min-height: 100%;
        padding: 35px 0 0 0;

        .introduce {
            padding: 0 0 10px 30px;
            font-size: 12px;
            font-weight: 400;
            color: #9AB6FF;
            line-height: 12px;
        }

        .icon {
            font-size: 24px;
            margin-right: 16px;
            vertical-align: middle;
        }

        .big-icon.icon {
            font-size: 28px;
            flex: none;
        }
    }

    .el-menu-item.is-active {
        background-color: rgba(0, 0, 0, 0.2) !important;
        color: #fff;
        border-radius: 0 !important;
    }

    .el-menu--popup-right-start {
        background-color: white !important;
    }

    .el-menu-vertical-demo {
        min-width: 80px !important;
    }

    .el-menu--collapse {
        text-align: center;
        color: #C2D3FF !important;

        .introduce {
            padding-left: 0px;
        }

        .el-menu-item .is-active {
            background: rgba(0, 0, 0, 0.2) !important;
        }

        .logo img {
            width: 48px;
            height: 48px;
        }

        .el-submenu {
            padding: 0 16px;
            margin: 4px 0;

            &:first-child {
                margin-top: 0;
            }

            &.is-active .el-submenu__title {
                background-color: rgba(0, 0, 0, 0.2) !important;
            }
        }

        .is-active {
            color: white !important;
            border-radius: 4px;
        }

        .el-tooltip {
            display: flex !important;
            justify-content: center;
            align-items: center;

            .icon {
                margin-right: 0;
            }
        }

        .el-submenu__title {
            display: flex !important;
            justify-content: center;
            align-items: center;
            border-radius: 8px 8px 8px 8px;
            padding: 0 !important;

            .icon {
                margin-right: 0;
            }
        }

        .el-submenu__title:hover {
            background: rgba(0, 0, 0, 0.2) !important;
        }

        .el-menu-item {
            margin: 4px 16px;
            border-radius: 8px !important;

            &:hover {
                background-color: rgba(0, 0, 0, 0.2) !important;
            }

            &:first-child {
                margin-top: 0;
            }
        }

    }

    .el-submenu__icon-arrow {
        transform: rotate(-90deg);
    }

    .el-submenu.is-opened>.el-submenu__title .el-submenu__icon-arrow {
        transform: rotateZ(0);
    }

    .el-icon-arrow-right:before {
        display: none;
    }

    .title-first {
        height: 56px !important;
        line-height: 56px !important;
    }

    .title-first:hover {
        color: #fff !important;
    }

    .child-nav:hover {
        color: #fff !important;
    }
}
</style>

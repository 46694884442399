export default [
  {
    name: "运行概览",
    route: "/visual/run-overview",
    icon: "tv",
    has_child: 1,
    page: [
      {
        name: "文字数据",
        route: "/visual/run-overview-text",
      },
      {
        name: "图形数据",
        route: "/visual/run-overview-graph",
      },
    ],
  },
  {
    name: "经济分析",
    route: "/visual/economic-analysis",
    icon: "analysis",
    has_child: 1,
    page: [
      {
        name: "文字数据",
        route: "/visual/economic-analysis-text",
      },
      {
        name: "图形数据",
        route: "/visual/economic-analysis-graph",
      },
    ],
  },
  {
    name: "龙头企业",
    route: "/visual/Lead-enterprises",
    icon: "build",
    has_child: 1,
    spacing: 1.2,
    page: [
      {
        name: "文字数据",
        route: "/visual/Lead-enterprises-text",
      },
      {
        name: "图形数据",
        route: "/visual/Lead-enterprises-graph",
      },
    ],
  },
  {
    name: "招商管理",
    route: "/visual/attract-investment",
    icon: "zhaoshang",
    has_child: 1,
    page: [
      {
        name: "文字数据",
        route: "/visual/attract-investment-text",
      },
      {
        name: "图形数据",
        route: "/visual/attract-investment-graph",
      },
      {
        name: "医疗器械产业图谱",
        route: "/visual/attract-industry-map",
      },
    ],
  },
  {
    name: "智慧党建",
    route: "/visual/party-build",
    icon: "party",
    has_child: 1,
    page: [
      {
        name: "文字数据",
        route: "/visual/party-build-text",
      },
      {
        name: "图形数据",
        route: "/visual/party-build-graph",
      },
      {
        name: "党建图片",
        route: "/visual/party-build-pic",
      },
      {
        name: "演讲内容",
        route: "/visual/party-speech",
      },
    ],
  },
  {
    name: "安全管理",
    route: "/visual/safe",
    icon: "shield",
    has_child: 1,
    page: [
      {
        name: "基本信息",
        route: "/visual/safe-base-info",
      },
      {
        name: "危化重点企业",
        route: "/visual/safe-hazardous-company",
      },
      {
        name: "排查任务",
        route: "/visual/safe-investigate-task",
      },
      {
        name: "使用燃气列表",
        route: "/visual/safe-use-gas",
      },
      {
        name: "有限空间企业",
        route: "/visual/safe-limited-company",
      },
      {
        name: "工贸行业企业",
        route: "/visual/safe-industry-trade",
      },
      {
        name: "仓储物流企业",
        route: "/visual/safe-storage-logistics",
      },
      {
        name: "高层建筑",
        route: "/visual/safe-high-build",
      },
      {
        name: "消防安全检查",
        route: "/visual/safe-security-check",
      },
      {
        name: "有限空间作业安全检查",
        route: "/visual/safe-limited-space",
      },
    ],
  },
  {
    name: "地块管理",
    route: "/visual/landbusiness",
    icon: "land",
    has_child: 1,
    page: [
      {
        name: "地块情况",
        route: "/visual/landbusiness-land",
      },
      {
        name: "使用单位",
        route: "/visual/landbusiness-useunit",
      },
    ],
  },
  {
    name: "基础信息",
    route: "/visual/base-info",
    icon: "info",
    has_child: 1,
    page: [
      {
        name: "企业信息",
        route: "/visual/enterprise-info",
      },
      {
        name: "项目信息",
        route: "/visual/project-info",
      },
      {
        name: "土建项目",
        route: "/visual/civil-engineer",
      },
      {
        name: "政策库",
        route: "/visual/policy-library",
      },
    ],
  },
  {
    name: "经开区介绍",
    route: "/visual/introduce",
    icon: "carousel",
    has_child: 1,
    page: [
      {
        name: "配套设施",
        route: "/visual/introduce-peitaosheshi",
      },
      {
        name: "区位优势",
        route: "/visual/introduce-quweiyoushi",
      },
      {
        name: "一区多园",
        route: "/visual/introduce-yiquduoyuan",
      },
      {
        name: "载体介绍",
        route: "/visual/introduce-zaitijieshao",
      },
      {
        name: "医疗器械产业园",
        route: "/visual/introduce-chanyeyuan",
      },
      {
        name: "帝达东谷园",
        route: "/visual/introduce-dongguyuan",
      },
    ],
  },
];

import axios from "axios";
import ElementUI from "element-ui";
import { message } from "./message";
import router from "@/router";
// 创建axios实例
const service = axios.create({
  // baseURL: "http://192.168.9.47/jkqapi/public",
  baseURL: "https://dljkq.lianghang.cc",
  timeout: 8000, // 请求超时时间
  //withCredentials: true,
  data: {},
  // crossDomain: true
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("TOKEN");
    // let x_token = localStorage.getItem("x_token");
    let tmp = localStorage.getItem("tmp_token");
    // let platform = localStorage.getItem('platform')
    // if(platform){
    //     config.headers['abc'] = platform
    // }
    if (tmp) {
      config.headers["Authorization"] = tmp;
    } else {
      // console.log(config);
      if (config.url == "/v1/upload") {
        config.params = { token };
      } else {
        config.headers["Authorization"] = token; // 让每个请求携带自定义token 请根据实际情况自行修改
      }
      // config.headers["X-CSRF-TOKEN"] = x_token;
    }
    // var lang = localStorage.getItem("lang"); //因为项目中使用到了i18n国际化语言配置，请根据实际情况自行修改
    // if (!lang) {
    //   lang = "zh_CN";
    // }
    // config.headers['Accept-Language'] = lang.replace(/_/g, '-')
    // config.headers['Content-Type'] = 'application/json'
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// response 拦截器
service.interceptors.response.use(
  (response) => {
    if (response.data.code === 401) {
      //  store.dispatch('LogOut').then(() => {
      //      // 用户登录界面提示
      //      Cookies.set('point', 401)
      //      location.reload()
      // })
    } else if (response.data.code === 303) {
      message({
        message: "登录超时，请重新登录！",
        type: "error",
      });
      setTimeout(() => {
        window.localStorage.removeItem("TOKEN");
        window.localStorage.removeItem("username");
        router.push({ path: "/login" });
      }, 3000);
    }
    return response.data;
  },
  (error) => {
    let code = 0;
    try {
      code = error.response.data.status;
    } catch (e) {
      if (error.toString().indexOf("Error: timeout") !== -1) {
        // Notification.error({
        //     title: '网络请求超时',
        //     duration: 5000
        // })
        return Promise.reject(error);
      }
    }

    if (code) {
      if (code === 401) {
        //  store.dispatch('LogOut').then(() => {
        //      // 用户登录界面提示
        //      Cookies.set('point', 401)
        //      location.reload()
        // })
      } else if (code === 303) {
        message({
          message: "登录超时，请重新登录！",
          type: "error",
        });
        setTimeout(() => {
          localStorage.removeItem("userpower");
          localStorage.removeItem("userbtn");
          // localStorage.removeItem("username");
          // localStorage.removeItem("route");
          // localStorage.removeItem("x_token");
          // localStorage.removeItem("userimage");
          localStorage.removeItem("TOKEN");
          localStorage.removeItem("USERNAME");
          router.push({ path: "/login" });
        }, 3000);
        return;
      } else {
        const errorMsg = error.response.data.message;
        if (errorMsg !== undefined) {
          // Notification.error({
          //     title: errorMsg,
          //     duration: 0
          // })
        }
      }
    } else {
      // Notification.error({
      //     title: '接口请求失败',
      //     duration: 5000
      // })
    }
    return Promise.reject(error);
  }
);
export default service;

<template>
    <div class="flex" style="height: 100%;">
        <div class="logo-title">
            <!-- <img v-if="nowproject == 'jingkai'" src="@/assets/image/jingkai-logo.png" alt="" style="margin-top: -4px;"> -->
            <img src="@/assets/image/login-logo.png" alt="">
        </div>
        <span class="separator"></span>

        <div class="systems">
            <div style="display: flex;justify-content: center;">
                <div id="headMenu" class="flex" style="overflow: hidden;" ref="headMenu"
                    :style="{ 'justify-content': moreMenu.length > 0 ? 'flex-start' : 'center' }">
                    <div class="systems-name" :class="{ 'active': item.value == nowproject }"
                        v-for="(item, index) in systemlist" :key="index" @click="changeSystem(item.value)">
                        <span>{{ item.name }}</span>
                    </div>
                    <el-dropdown v-if="moreMenu.length > 0" trigger="click" class="more-item" @command="changeSystem">
                        <div class="more-tit" style="font-weight:400">
                            <span>更多</span>
                            <local-svg class="omit" iconClass="vector"></local-svg>
                        </div>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :class="{ 'active': item.value == nowproject }" :command="item.value"
                                v-for="(item, index) in moreMenu" :key="index">
                                {{ item.name }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
        <div class="user-info flex">
            <!-- <el-tooltip content="扫码访问" popper-class="handlerTab_tooltip" placement="bottom" effect="light">
                <local-svg class="qrcode" iconClass="group" @click.native="showCode = true"></local-svg>
            </el-tooltip> -->
            <a style="margin-left: 24px;" @click="handleMessage">
                <el-badge :value="allhandle" :max="999" class="item hover" :hidden="allhandle == 0 ? true : false">
                    <local-svg class="list-image msg hover" iconClass="msg"></local-svg>
                </el-badge>
            </a>
            <span class="separator"></span>
            <img class="user-avatar" :src="!!$store.state.userimage ? $store.state.userimage : defaultImg" alt=""
                style="border-radius: 50%;">
            <el-dropdown @command="handleCommand" trigger="click" @visible-change="visibleChange">
                <span class="el-dropdown-link">
                    <div>
                        <span style="margin-right: 10px;">{{ $store.state.username }}</span>
                        <local-svg class="user-show hover" :class="{ 'visible': visible }"
                            iconClass="admindown"></local-svg>
                    </div>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="person">个人中心</el-dropdown-item>
                    <el-dropdown-item command="quit">退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>


        <el-dialog title="访问更多" :visible.sync="showCode" width="480px" class="addform codeform">
            <div class="flex">
                <div class="left-box">
                    <div class="code-tit flex" v-for="(item, index) in list" :key="index"
                        :class="{ 'active': index == activeIndex }" @click="activeIndex = index">
                        <span class="dot"></span>
                        <span>{{ item.name }}</span>
                    </div>
                </div>
                <div class="right-box">
                    <div class="code-img">
                        <img :src="list[activeIndex].img" alt="">
                    </div>
                    <span>{{ list[activeIndex].name }}</span>
                </div>
            </div>
        </el-dialog>

        <el-drawer :visible.sync="showMsg" :size="480" direction="rtl" :show-close="false" :before-close="handleClose">
            <div class="tab-title">
                <div class="tab-title-item" v-for="(item, index) in  tabList " :key="index"
                    :class="{ 'actived': index == tabindex }" @click="changeTab(index)">
                    <el-badge :value="item.total" :max="999" class="item hover" :hidden="item.total == 0 ? true : false">
                        <span>{{ item.name }}</span>
                    </el-badge>
                </div>
            </div>
            <div class="tab-content">
                <div class="empty-info" v-if="todoList.length == 0">
                    <div class="empty-content">
                        <img src="@/assets/image/none.png" alt="">
                        <span>暂无内容</span>
                    </div>
                </div>
                <template v-else-if="tabindex == 0">
                    <div class="row-msg" :class="{ 'new': item.deal === 0 }" @click="handleFun(item)"
                        v-for="( item, index ) in  todoList " :key="index">
                        <div class="flex">
                            <div class="tit">{{ item.typeName }}</div>
                            <div class="time">{{ item.create_time }}</div>
                        </div>
                        <div class="msg">{{ item.matter }}</div>
                    </div>
                </template>
                <template v-else-if="tabindex == 1">
                    <div class="row-msg message" :class="{ 'new': item.is_read === 0 }"
                        v-for="( item, index ) in  messageList" :key="index">
                        <div class="flex">
                            <div class="tit">{{ item.content }}</div>
                            <div class="time">{{ item.create_time }}</div>
                        </div>
                    </div>
                </template>
            </div>
            <div class="tab-footer">
                <el-button class="seemove" @click="seeMove" type="text">查看更多>></el-button>
            </div>
        </el-drawer>

    </div>
</template>
 
<script>
import { todoList, message, clearmessage } from "@/api/system"

export default {
    name: 'Header',
    props: {
        systemlist: {
            type: Array,
            default: function() {
                return []
            }
        }
    },
    data() {
        return {
            // username: '',
            // userimage: this.$store.state.userimage,
            visible: false,
            defaultImg: require('@/assets/image/user.jpg'),
            showCode: false,
            list: [
                { name: '智领经开', img: require('@/assets/image/jingkai.png') },
                { name: '智领安全', img: require('@/assets/image/security.png') },
                { name: '零碳岛', img: require('@/assets/image/ling-tan-dao.png') },
            ],
            activeIndex: 0,
            moreMenu: [],
            // totalMessage: 0,
            showMsg: false,
            todoList: [],
            messageList: [],
            tabList: [{ name: '待办事项', total: 0 }, { name: '消息通知', total: 0 }],
            tabindex: 0,
        }
    },
    computed: {
        nowproject: function() {
            return this.$route.path.split("/")[1];
        },
        allhandle: function() {
            let count = this.tabList.reduce((a, b) => a.total + b.total)
            return count
        }
    },
    methods: {
        changeSystem(value) {
            this.$router.push({ name: value })
        },
        logout() {
            window.localStorage.removeItem('TOKEN');
            window.localStorage.removeItem('username');
            window.localStorage.removeItem('userbtn');
            window.localStorage.removeItem('userpower');
            window.localStorage.removeItem('userimage');
            this.$router.push('/login')
        },
        handleCommand(command) {
            if (command == 'person') {
                this.$router.push('/system/personal')
            } else if (command == 'quit') {
                this.logout();
            }
        },
        visibleChange(value) {
            this.visible = value;
        },
        changeStyle() {
            this.$nextTick(function() {
                let groups = document.querySelectorAll('.systems-name');
                for (let i = 0; i < groups.length; i++) {
                    groups[i].style.display = 'block'
                }

                let headmenu = document.getElementById('headMenu');
                if (!headmenu) return;
                let width = headmenu.offsetWidth

                if (headmenu.scrollWidth > width) {
                    let arr = [];
                    let groups = document.querySelectorAll('.systems-name');
                    let num = 0;
                    for (let i = 0; i < groups.length; i++) {
                        num = i == 0 ? num + groups[i].offsetWidth : num + groups[i].offsetWidth + 36;
                        if (num + 80 > width) {
                            groups[i].style.display = 'none'
                            arr.push({ ...this.systemlist[i] })
                        }
                    }
                    this.moreMenu = arr;
                } else {
                    this.moreMenu = [];
                }
            })

        },
        handleMessage() {
            this.showMsg = true;
        },
        handleClose() {
            this.showMsg = false;
        },
        getTodoList() {
            let p1 = todoList({ page: 1, limit: 10, deal: 2 })
            let p2 = message({ page: 1, limit: 50, });
            Promise.all([p1, p2]).then(res => {
                if (res[0].code != 200) {
                    this.$message.error(res[0].code)
                } else if (res[1].code != 200) {
                    this.$message.error(res[1].code)
                } else {
                    this.tabList[0].total = res[0].data.count;
                    this.todoList = res[0].data.list;
                    this.tabList[1].total = res[1].data.handle;
                    this.messageList = res[1].data.data;
                }
            })
        },
        seeMove() {
            this.showMsg = false;
            if (this.tabindex == 0) {
                this.$router.push('/system/todolist')
            } else {
                this.$router.push('/system/message')
            }
        },
        changeTab(index) {
            this.tabindex = index;
            if (index == 1) {
                let arr = this.messageList.filter(x => x.is_read == 0);
                if (arr.length > 0) {
                    arr = arr.map(x => x.id)
                    clearmessage({ id: arr.toString() }).then(res => {
                        if (res.code == 200) {
                            this.getTodoList()
                        } else {
                            this.$message.error(res.code)
                        }
                    })
                }

            }
        },
        handleFun(row) {
            this.showMsg = false;
            if (row.type === 1) {
                sessionStorage.setItem('industrialId', row.data_id)
                this.$bus.$emit('industrialId', row.data_id);
                this.$router.push({ path: '/jingkai/industrial-list' })
            } else if (row.type === 2) {
                sessionStorage.setItem('commentId', JSON.stringify({ id: row.data_id, type: 1 }))
                this.$bus.$emit('commentId', { id: row.data_id, type: 1 });
                this.$router.push({ path: '/jingkai/industrial-comment' })
            } else if (row.type === 3) {
                sessionStorage.setItem('reservationId', row.data_id)
                this.$bus.$emit('reservationId', row.data_id);
                this.$router.push({ path: '/jingkai/activity-reservation' })
            } else if (row.type === 4) {
                sessionStorage.setItem('commentId', JSON.stringify({ id: row.data_id, type: 2 }))
                this.$bus.$emit('commentId', { id: row.data_id, type: 2 });
                this.$router.push({ path: '/jingkai/industrial-comment' })
            } else if (row.type === 5) {
                if (row.data_id) {
                    sessionStorage.setItem('safeCheck', JSON.stringify({ id: row.secure_id, type: 2, son_id: row.data_id }))
                    this.$bus.$emit('safeCheck', { id: row.secure_id, type: 2, son_id: row.data_id });
                    this.$router.push({ path: '/security/safe-check' })
                } else {
                    this.$router.push({ path: '/system/enterprise-user' })
                }
            }
        },
    },
    mounted() {
        this.changeStyle();
        window.addEventListener('resize', this.changeStyle)
        this.getTodoList()
        this.timer = setInterval(() => {
            this.getTodoList()
        }, 10000);

    },
    destroyed() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }
}
</script>
 
<style scoped lang="scss">
.logo-title {
    padding-left: 26px;
    flex: none;

    img {
        height: 36px;
        user-select: none;
        vertical-align: middle;
    }
}

.separator {
    display: block;
    width: 1px;
    height: 20px;
    margin-left: 24px;
    flex: none;
    background: transparent;
}


.systems {
    flex: 1;
    overflow: hidden;
    margin: 0 10.4% 0 3.5%;

    .systems-name {
        font-size: 14px;
        font-weight: bold;
        color: #606B83;
        line-height: 16px;
        margin-left: 36px;
        cursor: pointer;
        white-space: nowrap;

        &:first-child {
            margin-left: 0;
        }

        &.active {
            color: #1D78FE;
        }

    }

    .systems-name:hover {
        color: #1D78FE;
    }

    .more-item {
        margin-left: 36px;

        .more-tit {
            font-size: 14px;
            font-weight: 400;
            color: #606B83;
            line-height: 16px;
            cursor: pointer;
            white-space: nowrap;
        }

        .more-tit:hover {
            color: #1D78FE;
        }

        .omit {
            font-size: 13px;
        }
    }
}

.user-info {
    // height: 16px;
    font-size: 14px;
    font-weight: 400;
    color: #A3AECB;
    line-height: 16px;
    padding-right: 36px;
    flex: none;
    cursor: pointer;

    .qrcode {
        font-size: 24px;
        color: #A3AECB;

        :hover {
            color: #1D78FE;
        }
    }

    .separator {
        display: block;
        width: 1px;
        height: 20px;
        background: #E2E7EE;
        margin-right: 24px;
    }

    &:hover {
        color: #1D78FE;
    }
}

.user-avatar {
    width: 36px;
    height: 36px;
    margin-right: 14px;
}

.el-dropdown-link {
    color: #b0b1b9;

    &:hover {
        color: #1D78FE;
    }
}

.left-box {
    padding-top: 2px;
    padding-left: 36px;
    padding-right: 26px;
    height: 306px;
    width: 152px;
    border-right: 1px solid #F0F1F5;
    flex: none;
    overflow: auto;

    .code-tit {
        margin-top: 24px;
        cursor: pointer;

        &.active {
            span {
                font-weight: bold;
                color: #1A6BFF;
            }

            .dot {
                background: #1D78FE;
            }
        }

        &:hover span {
            color: #1D78FE;
        }


        .dot {
            content: '';
            width: 4px;
            height: 4px;
            background: #E3E4EA;
            margin-right: 7px;
            vertical-align: middle;
            border-radius: 2px;
            flex: none;
        }

        span {
            flex: 1;
            line-height: 18px;
            font-size: 14px;
            font-weight: 400;
            color: #3F5A8B;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

.right-box {
    flex: 1;
    height: 306px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #A3AECB;

    .code-img {
        width: 176px;
        height: 176px;
        padding: 4px;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #F0ECEC;
        margin: 40px auto 14px;
        overflow: hidden;

        img {
            width: 100%;
        }

    }
}

.svg-icon.msg {
    font-size: 24px;
    color: #A3AECB;

    &:hover {
        color: #1D78FE;
    }
}

::v-deep .el-badge .el-badge__content {
    background-color: #F33232;
}

.tab-footer {
    height: 80px;
    padding-top: 8px;
    padding-right: 30px;
    text-align: right;
    color: #475b93;
    border-top: 1px solid #F0F1F5;

    .seemove:hover {
        color: #1D78FE;
    }
}

.tab-title {
    border-bottom: 1px solid #F0F1F5;
    height: 60px;
    display: flex;
    position: relative;

    .tab-title-item {
        width: 160px;
        height: calc(100% + 1.5px);
        font-size: 14px;
        font-weight: bold;
        color: #353940;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 2px solid transparent;
        cursor: pointer;

        &.actived {
            border-bottom: 2px solid #1D78FE;
            color: #1D78FE;
        }

        ::v-deep .el-badge__content {
            right: 0px;
        }
    }
}

.tab-content {
    height: calc(100% - 140px);
    padding-top: 12px;
    overflow: auto;

    .row-msg {
        margin-left: 48px;
        margin-right: 31px;
        padding-top: 16px;
        border-bottom: 1px dotted #EAECF5;
        cursor: pointer;

        &.new .flex::before {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            background: #F33232;
            border-radius: 8px 8px 8px 8px;
            left: -18px;
        }

        &.new .flex .tit {
            color: #6B6D77;
        }

        .flex {
            justify-content: space-between;
            margin-bottom: 16px;
            position: relative;

            .tit {
                font-size: 14px;
                font-weight: bold;
                color: #B0B1B9;
            }

            .time {
                font-size: 12px;
                font-weight: 400;
                color: #B0B1B9;
            }
        }

        .msg {
            margin-bottom: 16px;
            font-size: 14px;
            font-weight: 400;
            color: #B0B1B9;
            line-height: 22px;
        }

        &.message {
            .tit {
                white-space: nowrap;
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: 400;
            }

            .time {
                margin-left: 10px;
            }
        }
    }
}

::v-deep #el-drawer__title {
    padding: 0;
    margin-bottom: 0;
}
</style>

<style lang="scss">
.el-dropdown-menu__item {
    color: #9ca3b6;
}

.user-show.visible {
    transform: rotateZ(180deg);
    vertical-align: -1px !important;
}



.handlerTab_tooltip,
.handlerTab_tooltip.is-light {
    border: none !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 16px 0px !important;
    color: #4D535C !important;
    line-height: 20px !important;
    padding: 7px 16px !important;

    &.el-tooltip__popper .popper__arrow {
        /* border-bottom-color: #fff; */
        border: none;
    }

    &.el-tooltip__popper .popper__arrow::after {
        border-color: #fff;
        border: none;
    }
}


.codeform {

    .el-dialog {
        border-radius: 8px !important;
    }

    .el-dialog__body {
        padding: 0 !important;
    }
}

.el-dropdown-menu__item.active {
    color: #1D78FE;
    font-weight: bold;
}

.empty-info {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .empty-content {
        margin-bottom: 15%;

        img {
            display: block;
        }

        span {
            display: block;
            color: #D0D0D2;
            font-size: 14px;
            text-align: center;
            margin-top: -55px;
        }
    }

}
</style>